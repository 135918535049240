export const propertyValueTooltip = {
	"header": "Wartość nieruchomości",
	"description": "Policz ile kosztowałoby zakup takiego mieszkania na rynku oraz wyposażenia go w elementy stałe, takie jak armatura, okładziny ścian, podłóg i sufitów itp.\n" +
		"\n" +
		"Jak obliczyć wartość domu?\n" +
		"Policz ile kosztowałoby zbudowanie takiego domu od podstaw wraz z wyposażeniem go w elementy stałe, takie jak armatura, okładziny ścian, podłóg i sufitów, kominki i piece grzewcze itp."
}

export const permanentItemsInsuranceAmountTooltip = {
	"header": "Ubezpieczenie elementów stałych",
	"description": "Elementy stałe to np.: armatura (wanna, krany, ceramika łazienkowa...), pokrycia ścian, sufitów i podłóg (powłoki malarskie, tapety, parkiet, glazura, terakota...), ale również meble w zabudowie i wyposażenie na trwałe połączone z konstrukcją nieruchomości (np.: zabudowa szaf wnękowych, stała zabudowa kuchenna, w tym sprzęt kuchenny w zabudowie).\n" +
		"\n" +
		"Obliczając wartość elementów stałych zastanów się ile kosztowałby zakup takich samych bądź podobnych rzeczy w sklepie oraz ich montaż."
}

export const equipmentInsuranceAmountTooltip = {
	"header": "Ubezpieczenie wyposażenia",
	"description": "Wyposażenie (nazywane też mieniem ruchomym) to wszelkie wolnostojące meble, wolnostojące sprzęty AGD, RTV i elektronika, elementy dekoracyjne i użytkowe (np: dywany, firany, naczynia kuchenne, ubrania), które można ubezpieczyć od zniszczenia w pożarze bądź innym zdarzeniu losowym oraz od kradzieży.Obliczając wartość wyposażenia zastanów się ile kosztowałby zakup takich samych bądź podobnych rzeczy w sklepie."
}

export const phoneNumberTooltip = {
	"header": "Numer telefonu",
	"description": "Podaj swój telefon komórkowy."
}

export const emailTooltip = {
	"header": "Adres email",
	"description": "Podaj swój adres e-mail, aby otrzymać na swoją skrzynkę oferty wraz z linkiem umożliwiającym powrót do wyliczenia bez potrzeby ponownego wypełniania formularza. Podanie adresu e-mail jest nieobowiązkowe."
}