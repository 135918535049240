import React from "react"
import styled from "styled-components"
import XIcon from "../../images/OtherAssets/x-icon.svg"
import { Link } from "gatsby"
import NextStepButton from "../common/NextStepButton";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import {toggleLoadDataFromLocalstorage} from "../../state/loadDataFromLocalStorage";
import {toggleModalVisibility} from "../../state/showLoadDataModal";
import {ButtonsContainer, SimpleButton} from "../loadDataModal/LoadDataModal";

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 100000000000000000;
  @media(min-width: 1024px) {
  	margin-left: -300px;
  }
  @media(min-width: 1280px) {
  	margin-left: -350px;
  }
  @media(min-width: 1920px) {
  	margin-left: -405px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: ${({theme}) => theme.colors.formBlue};
  border-radius: 25px;
  position: relative;
  max-width: 550px;
  margin: 0 auto;
  padding: 30px 0;
  @media(min-width: 768px) {
  	max-width: 700px;
  }
  @media(min-width: 1024px) {
    width: 800px;
    height: 370px;
    margin: 0 auto;
    max-width: unset;
  }
`

const Header = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.bold};
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	position: relative;
	text-align: center;
	padding: 50px 10% 15px;
	width: 100%;
	font-size: 20px;
	@media(min-width: 768px){
		font-size: 32px;
		line-height: 38px;
		padding: 40px 10% 35px;
	}
`;

const Description = styled.div`
	font-size: 14px;
	line-height: 22px;
	font-weight: ${({theme}) => theme.fontWeight.light};
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	position: relative;
	text-align: center;
	padding: 10px 10% 20px;
	width: 100%;
	span {
	  font-weight: ${({theme}) => theme.fontWeight.semiBold};
	}
	@media(min-width: 768px){
		font-size: 22px;
		line-height: 38px;
		padding: 0 10% 20px;
	}
`;

const CloseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.formAlwisOrange};
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  z-index: 2000;
  width: 35px;
  height: 35px;
  top: 15px;
  right: 15px;
  @media(min-width: 768px) {
    top: 25px;
    right: 25px;
  }
`

const CloseImage = styled.img`
  width: 12px; 
  margin: 0 auto;
  @media(min-width: 1024px) {
    width: 12px;
  }
`

const Popup = ({onClick}) => {
  return (
    <PopupWrapper>
      <InfoWrapper  key="popup" >
        <CloseWrapper onClick={onClick} as={Link} to={'/'}>
          <CloseImage src={XIcon}/>
        </CloseWrapper>
          <Header>Twoje zgłoszenie zostało zapisane!</Header>
          <Description>Dziękujemy za cierpliwość i wypełnienie wszystkich pól w formularzu!<br/>Nasz doradca skontaktuje sie z Tobą w przeciągu <span>24h.</span><br/>
            Do usłyszenia!</Description>
	      <ButtonsContainer>
		      <SimpleButton id={1}
		                    key={1}
		                    htmlId={'request-send-ok'}
		                    as={Link} to={'/'}
		                    onClick={onClick}>OK</SimpleButton>
	      </ButtonsContainer>
      </InfoWrapper>
    </PopupWrapper>
  )
}

export default Popup
