import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Header} from "../common/Header";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import {connect, useDispatch, useSelector} from "react-redux"
import {setFormValueAction} from "../../state/form";
import {ContentWrapper} from "../common/ContentWrapper";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import Popup from "../popup/Popup"
import {setFifthStepFilled} from "../../state/setPagesAreFilled"
import {togglePopupVisibility} from "../../state/showPopup";
import {
    BlueHint,
    ErrorMessageText,
    InvisibleWrapper,
    RadioButtonsTwoOptionsWrapper,
    TabWrapper
} from "../sharedComponents/SharedComponents";
import {prefferedContactTimeData} from "../../dataSources/Page2DataSource";
import {CategoryHeader} from "../common/CategoryHeader";
import {yesOrNo} from "../../dataSources/CommonDataSource";
import sendDataToFortWebApi, {sendDataToFortWebApiAction} from "../../state/sendDataToFortWebApi";
import {
    emailTooltip,
    equipmentInsuranceAmountTooltip,
    permanentItemsInsuranceAmountTooltip, phoneNumberTooltip,
    propertyValueTooltip
} from "../../dataSources/Page5DataSource";
import {change, Field, formValueSelector, reduxForm} from "redux-form";
import SingleTextInput from "../commonReduxFormComponents/SingleTextInput";
import CustomSelectReduxForm from "../commonReduxFormComponents/CustomSelectReduxForm";
import SingleRadioButtonTwoOptionsReduxForm from "../commonReduxFormComponents/SingleRadioButtonTwoOptionsReduxForm";
import SingleCheckboxReduxForm from "../commonReduxFormComponents/SingleCheckboxReduxForm";
import {sendEmailAction} from "../../state/sendEmail";
import lead, {changeLead} from "../../state/lead";

const CheckboxWrapper = styled.div`
	margin: 30px 0;
`
let CleanFifthStep = ({
                          handleSubmit, value, load, pristine, reset, submitting, houseType,
                          roofType, wallsConstructionType, propertyForRent, propertyArea, buildYearLabel,
                          propertyType, houseOwnershipType,propertyInConstructionOwnershipType, flatOwnershipType, change, city, economicActivity, assignmentToBank, yearsPropertyWasInsured,
                          lastInsuranceAgency,
                          floodDamageAmount, multiValveLockAmount, certifiedLock, phoneNumber,
                          isOCInsuranceInPrivateLife,
                      }) => {
    const [alreadySubmitting, setAlreadySubmitting] = useState(false);
    const dispatch = useDispatch()
    const formReducer = useSelector(state => state.form)
    const setPagesAreFilledReducer = useSelector(state => state.setPagesAreFilled);
    const isPopupShown = useSelector((state) => state.showPopup.isPopupShown);
    const isSubmitting = setPagesAreFilledReducer.fifthStepFilled;

    useEffect(() => {
        if (isSubmitting && !alreadySubmitting) {
            setAlreadySubmitting(true);
            console.log('form reducer ' + JSON.stringify(formReducer))
	        // console.log(values)
            // dispatch(sendEmailAction(formReducer))
            // const requestData = {
            // 	//strona 1
            // 	"lead": {
            // 		"agentSymbol": "ALWIS"
            // 	},
            // 	"person": {
            // 		"emailAddress": formReducer?.emailAddress || "",
            // 		"phoneNumber": (formReducer?.phoneNumber !== null) ? (formReducer?.phoneNumber).toString() : '' || "",
            // 		"prefferedContactTimeLabel": formReducer?.prefferedContactTimeLabel || "", // edycja
            // 	},
            // 	"home": {
            // 		"propertyType": formReducer?.propertyTypeLabel || "",
            // 		"address": {
            // 			"zipCode": formReducer?.zipCode || "",
            // 			"lat": formReducer?.lat || "",
            // 			"lng": formReducer?.lng || "",
            // 			"city": formReducer?.city || "",
            // 			"street": formReducer?.street || "",
            // 			"buildingNumber": formReducer?.buildingNumber || "",
            // 			"flatNumber": (formReducer?.flatNumber !== null) ? (formReducer?.flatNumber).toString() : '' || "",
            // 			"flatFloor": formReducer?.flatFloorLabel || "", // edycja
            // 		},
            // 		"houseType": formReducer?.houseTypeLabel || "",
            // 		"roofType": formReducer?.roofTypeLabel || "",
            // 		"wallsConstructionType": formReducer?.wallsConstructionTypeLabel || "",
            // 		"buildYear": formReducer?.buildYearLabel || "",
            // 		"propertyArea": formReducer?.propertyArea + (formReducer.propertyArea !== null) ? 'm2': '' || "",
            // 		"beginningDateOfBuilding": formReducer?.beginningDateOfBuildingLabel || "",
            // 		"expectedBuildingFinishDate": formReducer?.expectedBuildingFinishDateLabel || "",
            // 		"progressOfWork": formReducer?.progressOfWorkLabel || "",
            // 		"lastRenovationDate": formReducer?.lastRenovationDateLabel || "",
            // 		"houseOwnershipType": formReducer?.houseOwnershipTypeLabel || "",
            // 		"flatOwnershipType": formReducer?.flatOwnershipTypeLabel || "",
            // 		"farmSize": formReducer?.farmSize + (formReducer.farmSize !== null) ? 'ha' : '' || "",
            // 		"propertyPermanentlyInhabited": formReducer?.propertyPermanentlyInhabitedLabel || "",
            // 		"economicActivity": formReducer?.economicActivityLabel || "",
            // 		"areaPartForEconomicActivity": formReducer?.areaPartForEconomicActivityLabel || "",
            // 		"economicActivityType": formReducer?.economicActivityTypeLabel || "",
            // 		"propertyForRent": (formReducer?.propertyForRent === 1),
            // 		"assignmentToBank": (formReducer?.assignmentToBank === 1),
            // 		"yearsPropertyWasInsured": formReducer?.yearsPropertyWasInsuredLabel || "",
            // 		"lastInsuranceAgency": formReducer?.lastInsuranceAgencyLabel || "",
            // 		"damageAmountLastYear": formReducer?.damageAmountLastYearLabel || "",
            // 		"damageAmountTwoYearsAgo": formReducer?.damageAmountTwoYearsAgoLabel || "",
            // 		"damageAmountThreeYearsAgo": formReducer?.damageAmountThreeYearsAgoLabel || "",
            // 		"floodDamageAmount": formReducer?.floodDamageAmountLabel || "",
            // 		"insuranceBeginningDate": formReducer?.insuranceBeginningDate || "",
            // 		"wallsAndPermanentElementsInsuranceCovered": formReducer?.wallsAndPermanentElementsInsuranceCovered || false,
            // 		"equipmentInsuranceCovered": formReducer?.equipmentInsuranceCovered || false,
            // 		"farmInsuranceCoverageObligatory": formReducer?.farmInsuranceCoverageObligatory || false,
            // 		"farmInsuranceCoverageVoluntary": formReducer?.farmInsuranceCoverageVoluntary || false,
            // 		"isRandomEventInsured": formReducer?.isRandomEventInsured || false,
            // 		"isTheftInsured": formReducer?.isTheftInsured || false,
            // 		"isFloodInsured": formReducer?.isFloodInsured || false,
            // 		"isVandalismInsured": formReducer?.isVandalismInsured || false,
            // 		"isGlassItemInsured": formReducer?.isGlassItemInsured || false,
            // 		"isIntercom": formReducer?.isIntercom || false,
            // 		"isLocalAlarm": formReducer?.isLocalAlarm || false,
            // 		"isAlarmWithIntervention": formReducer?.isAlarmWithIntervention || false,
            // 		"isConstantSupervision": formReducer?.isConstantSupervision || false,
            // 		"isSecurityDoor": formReducer?.isSecurityDoor || false,
            // 		"isMonitoring": formReducer?.isMonitoring || false,
            // 		"isWindowSecurity": formReducer?.isWindowSecurity || false,
            // 		"multiValveLockAmount": formReducer?.multiValveLockAmountLabel || "",
            // 		"certifiedLock": formReducer?.certifiedLockLabel || "",
            // 		"propertyValue": (formReducer?.propertyValue !== null) ? (formReducer?.propertyValue).toString() : '' || "",
            // 		"permanentItemsInsuranceAmount": (formReducer?.permanentItemsInsuranceAmount).toString() || "",
            // 		"equipmentInsuranceAmount": (formReducer?.equipmentInsuranceAmount).toString() || "",
            // 		"prefferedContactTimeLabel": formReducer?.prefferedContactTimeLabel || "",
            // 		"isOCInsuranceInPrivateLife": (formReducer?.isOCInsuranceInPrivateLife === 1),
            // 	}
            // }
            // console.log('request data ' + JSON.stringify(requestData))
            // dispatch(sendDataToFortWebApiAction(requestData))
            dispatch(setFifthStepFilled(true))
        }
    }, [isSubmitting])

    const [isDataProcessingAccepted, toggleAcceptDataProcessing] = useState(false);
    const handleDataProcessingAccept = () => {
        toggleAcceptDataProcessing(!isDataProcessingAccepted);
        dispatch(setFormValueAction("dataProcessingAccepted", !isDataProcessingAccepted));
    }

    const [isGettingEmailsAccepted, toggleAccept] = useState(false);
    const handleGettingEmailsAccept = () => {
        toggleAccept(!isGettingEmailsAccepted);
        dispatch(setFormValueAction("gettingEmailsAccepted", !isGettingEmailsAccepted));
    }

    const handleClosePopup = () => {
        dispatch(togglePopupVisibility(false))
    }

    const isWallAndPermanentElementsVisible = (((propertyType === 1 || propertyType === 3 || propertyType === 4) && (houseOwnershipType && houseOwnershipType.value === 1) || (propertyInConstructionOwnershipType && propertyInConstructionOwnershipType.value === 1)) ||
        (propertyType === 2 && flatOwnershipType &&
            (flatOwnershipType.value === 1 || flatOwnershipType.value === 2)));
    console.log(isWallAndPermanentElementsVisible)

    const [propertyValueTooltipVisible, tooglePropertyValueVisibility] = useState(false)
    const handlePropertyValueTooltipVisibility = () => {
        tooglePropertyValueVisibility(!propertyValueTooltipVisible)
        tooglePermanentItemsInsuranceAmountVisibility(false)
        toogleEquipmentInsuranceAmountVisibility(false)
        tooglePhoneNumberVisibility(false)
        toogleEmailVisibility(false)
    }
    const [permanentItemsInsuranceAmountTooltipVisible, tooglePermanentItemsInsuranceAmountVisibility] = useState(false)
    const handlePermanentItemsInsuranceAmountTooltipVisibility = () => {
        tooglePermanentItemsInsuranceAmountVisibility(!permanentItemsInsuranceAmountTooltipVisible)
        tooglePropertyValueVisibility(false)
        toogleEquipmentInsuranceAmountVisibility(false)
        tooglePhoneNumberVisibility(false)
        toogleEmailVisibility(false)
    }
    const [equipmentInsuranceAmountVisible, toogleEquipmentInsuranceAmountVisibility] = useState(false)
    const handleEquipmentInsuranceAmountTooltipVisibility = () => {
        toogleEquipmentInsuranceAmountVisibility(!equipmentInsuranceAmountVisible)
        tooglePropertyValueVisibility(false)
        tooglePermanentItemsInsuranceAmountVisibility(false)
        tooglePhoneNumberVisibility(false)
        toogleEmailVisibility(false)
    }
    const [phoneNumberVisible, tooglePhoneNumberVisibility] = useState(false)
    const handlePhoneNumberTooltipVisibility = () => {
        tooglePhoneNumberVisibility(!phoneNumberVisible)
        tooglePropertyValueVisibility(false)
        tooglePermanentItemsInsuranceAmountVisibility(false)
        toogleEquipmentInsuranceAmountVisibility(false)
        toogleEmailVisibility(false)
    }
    const [emailTooltipVisible, toogleEmailVisibility] = useState(false)
    const handleEmailTooltipVisibility = () => {
        toogleEmailVisibility(!emailTooltipVisible)
        tooglePropertyValueVisibility(false)
        tooglePermanentItemsInsuranceAmountVisibility(false)
        toogleEquipmentInsuranceAmountVisibility(false)
        tooglePhoneNumberVisibility(false)
    }

    const handleValidation = values => {
        const errors = {};
        if (isWallAndPermanentElementsVisible) {
            if (!values.propertyValue) {
                errors.propertyValue = 'pole wymagane';
            }
        }
        if (!values.permanentItemsInsuranceAmount) {
            errors.permanentItemsInsuranceAmount = 'pole wymagane';
        }
        if (!values.equipmentInsuranceAmount) {
            errors.equipmentInsuranceAmount = 'pole wymagane';
        }
        if (!values.isOCInsuranceInPrivateLife) {
            errors.isOCInsuranceInPrivateLife = 'pole wymagane';
        }
        console.log('page5localstorage ' + JSON.stringify(errors))
        return errors;
    };
    return (
        <>
            <TabWrapper>
                <Header className="tablet-hidden">Wysokość ubezpieczenia</Header>
                <BlueHint className="tablet-hidden">wypełnij formularz</BlueHint>
                <ContentWrapper>
                    <form onSubmit={handleSubmit}>
                        {isWallAndPermanentElementsVisible &&
                        <Field
                            name="home[propertyValue]"
                            component={SingleTextInput}
                            type="number"
                            //validate={validate.isRequired}

                            editableInputHeader={propertyType === "Mieszkanie" ? "Suma ubezpieczenia mieszkania" : "Suma ubezpieczenia budynku wraz  stałymi elemetnami w budowie"}
                            placeholder={"Wpisz sumę"}
                            unitName="zł"
                            isNotMarginTop={true}
                            showCircle={true}
                            isFirstOnPage={isWallAndPermanentElementsVisible}
                            isWindowVisible={propertyValueTooltipVisible}
                            onClick={handlePropertyValueTooltipVisibility}
                            tooltipHeader={propertyValueTooltip.header}
                            tooltipDescription={propertyValueTooltip.description}
                        />
                        }
                        <InvisibleWrapper isVisible={propertyType !== "Dom w budowie"}>
                            <Field
                                name="home[permanentItemsInsuranceAmount]"
                                component={SingleTextInput}
                                type="number"
                                //validate={validate.isRequired}
                                editableInputHeader={"Suma ubezpieczenia stałych elementów"}
                                placeholder={"Wpisz sumę"}
                                unitName="zł"
                                isNotMarginTop={!isWallAndPermanentElementsVisible}
                                showCircle={true}
                                isFirstOnPage={!isWallAndPermanentElementsVisible}
                                isWindowVisible={permanentItemsInsuranceAmountTooltipVisible}
                                onClick={handlePermanentItemsInsuranceAmountTooltipVisibility}
                                tooltipHeader={permanentItemsInsuranceAmountTooltip.header}
                                tooltipDescription={permanentItemsInsuranceAmountTooltip.description}
                            />
                        </InvisibleWrapper>
                        <Field
                            name="home[equipmentInsuranceAmount]"
                            component={SingleTextInput}
                            type="number"
                            //validate={validate.isRequired}
                            editableInputHeader={"Suma ubezpieczenia wyposażenia"}
                            placeholder={"Wpisz sumę"}
                            unitName="zł"
                            showCircle={true}
                            isWindowVisible={equipmentInsuranceAmountVisible}
                            onClick={handleEquipmentInsuranceAmountTooltipVisibility}
                            tooltipHeader={equipmentInsuranceAmountTooltip.header}
                            tooltipDescription={equipmentInsuranceAmountTooltip.description}
                        />
                        <Field
                            name="person[emailAddress]"
                            component={SingleTextInput}
                            type="email"
                            //validate={validate.isRequired}
                            editableInputHeader={"Adres email"}
                            placeholder={"Podaj swój adres email"}
                            showCircle={true}
                            isWindowVisible={emailTooltipVisible}
                            onClick={handleEmailTooltipVisibility}
                            tooltipHeader={emailTooltip.header}
                            tooltipDescription={emailTooltip.description}
                        />
                        <Field
                            name="person[phoneNumber]"
                            component={SingleTextInput}
                            type="number"
                            //validate={validate.isRequired}
                            editableInputHeader={"Numer telefonu"}
                            placeholder={"Podaj swój numer telefonu"}
                            showCircle={true}
                            isWindowVisible={phoneNumberVisible}
                            onClick={handlePhoneNumberTooltipVisibility}
                            tooltipHeader={phoneNumberTooltip.header}
                            tooltipDescription={phoneNumberTooltip.description}
                        />
                        <InvisibleWrapper isVisible={phoneNumber}>
                            <Field
                                name="person[prefferedContactTime]"
                                component={CustomSelectReduxForm}
                                type="text"
                                options={prefferedContactTimeData}
                                isClockShown={true}
                                // validate={validate.isRequired}
                                selectLabel={"W jakich godzinach preferuje Pani/Pan kontakt?"}
                                placeholder={"wybierz godzinę kontaktu"}
                            />
                        </InvisibleWrapper>
                        <CategoryHeader className="margin-top">Czy posiada Pan/Pani ubezpieczenie OC w życiu
                            prywatnym?</CategoryHeader>
                        <RadioButtonsTwoOptionsWrapper className="margin-bottom">
                            {yesOrNo.map(({label, value}) => {
	                            const tempId = value === true ? 1 : 2;
	                            return (
                                <Field name="home[isOCInsuranceInPrivateLife]"
                                       id={tempId}
                                       key={tempId}
                                       htmlId={'oc-in-private-life-' + tempId}
                                       type="radio"
                                       component={SingleRadioButtonTwoOptionsReduxForm}
                                       amountName={label}
                                       activeId={isOCInsuranceInPrivateLife}
                                       isSmallMargin={false}
                                       onClick={(val) => {
	                                       if(val === 1) {
		                                       change('home[isOCInsuranceInPrivateLife]', true)
	                                       } else {
		                                       change('home[isOCInsuranceInPrivateLife]', false)
	                                       }
                                       }}/>
                            )})}
                        </RadioButtonsTwoOptionsWrapper>
                        <CheckboxWrapper>
                            <Field
                                name="home[dataProcessingAccepted]"
                                component={SingleCheckboxReduxForm}
                                type="checkbox"
                                required
                                checkboxId="checkbox-1"
                                checkboxText={"Oświadczam, że zapoznałem się i akceptuję regulaminy świadczenia usług przez Alwis&Secura Sp. z o.o."}
                                // validate={validate.isRequiredCheckbox}
                            />
                        </CheckboxWrapper>
                        <CheckboxWrapper>
                            <Field
                                name="home[gettingEmailsAccepted]"
                                component={SingleCheckboxReduxForm}
                                required
                                type="checkbox"
                                checkboxId="checkbox-2"
                                checkboxText={"Wyrażam zgodę na przetwarzanie powyższych danych osobowych przez Alwis&Secura Sp. z o.o. w celu umożliwienia kontaktu poprzez email (to znaczy otrzymywania drogą elektroniczną informacji handlowych w rozumieniu ustawy o świadczeniu usług drogą elektroniczną) i kontaktu poprzez sms i telefon (to znaczy na wykorzystywanie telekomunikacyjnych urzadzeń końcowych i automatycznych systemów wywołujących dla celów marketingu bezpośredniego w rozumieniu przepisów ustawy prawo telekomunikacyjne). Zastrzegam, że bez mojej zgody dane te nie mogą być udostępniane komukolwiek. Przysługuje mi prawo dostępu do treści danych oraz możliwość ich poprawiania. Zgody powyższej udzielam dobrowolnie."}
                                // validate={validate.isRequiredCheckbox}
                            />
                        </CheckboxWrapper>

                        <NavigationButtonsWrapper className="wrap-button">
                            <PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"}
                                                to="/step-4"/>
                            <NextStepButton className="send-form" type="submit"
                                            isDisabled={isSubmitting}
                                            isVisible={true} textBtn={"Wyślij formularz"}/>
                        </NavigationButtonsWrapper>
                    </form>
                </ContentWrapper>
            </TabWrapper>
            {isPopupShown && <Popup onClick={handleClosePopup}/>}
        </>
    )
};

const validate = values => {
    const errors = {};
    if ((((values.propertyType === "Dom" || values.propertyType === "Dom w budowie" || values.propertyType === "Gospodarstwo rolne") && values.houseOwnershipType && values.houseOwnershipType.value === 1) ||
        (values.propertyType === 2 && values.flatOwnershipType &&
            (values.flatOwnershipType.value === 1 || values.flatOwnershipType.value === 2)))) {
        if (!values.propertyValue) {
            errors.propertyValue = 'pole wymagane';
        }
    }
    if (!values.permanentItemsInsuranceAmount) {
        errors.permanentItemsInsuranceAmount = 'pole wymagane';
    }
    if (!values.equipmentInsuranceAmount) {
        errors.equipmentInsuranceAmount = 'pole wymagane';
    }
    if (!values.isOCInsuranceInPrivateLife) {
        errors.isOCInsuranceInPrivateLife = 'pole wymagane';
    }

    console.log('page5blank ' + JSON.stringify(errors))
    return errors;
};

const onSubmit = (values, dispatch, formReducer) => {
    localStorage.setItem('propertyForm', JSON.stringify(values));
    // registerField('propertyForm', 'street', 'string')
    // localStorage.setItem('form-data', JSON.parse(values));
    // alert(JSON.stringify(values))
    // alert(JSON.stringify(formReducer))
    console.log(formReducer)
    console.log(values)
	dispatch(sendDataToFortWebApiAction(values)).then(res => {
			if (res.value.status === 201) { // formularz istnieje i udostępnia dane
				const uuid = res?.value?.data?.leadUuid || '';
				dispatch(changeLead(uuid));
				dispatch(change("propertyForm", "lead[uuid]", uuid))
				// console.log(values)
				// dispatch(sendEmailAction(uuid, values)).then(response => console.log(response))
			}
		}
	)
	console.log(values)
	const leadToSendEmail = values?.lead?.uuid || '';
    console.log(leadToSendEmail);
	dispatch(sendEmailAction(leadToSendEmail, values)).then(response => console.log(response))

    dispatch(togglePopupVisibility(true))
    // dispatch(setFifthStepFilled(true))
    // const requestData = {
    //     //strona 1
    //     "lead": {
    //         "agentSymbol": "ALWIS"
    //     },
    //     "person": {
    //         "emailAddress": formReducer.values?.emailAddress || "",
    //         "phoneNumber": (formReducer.values?.phoneNumber !== null) ? (formReducer.values?.phoneNumber)?.toString() : '' || "",
    //         "prefferedContactTimeLabel": formReducer.values?.prefferedContactTime?.label || "", // edycja
    //     },
    //     "home": {
    //         "propertyType": formReducer.values?.propertyTypeLabel || "",
    //         "address": {
    //             "zipCode": formReducer.values?.zipCode || "",
    //             "lat": formReducer.values?.lat || "",
    //             "lng": formReducer.values?.lng || "",
    //             "city": formReducer.values?.city || "",
    //             "street": formReducer.values?.street || "",
    //             "buildingNumber": formReducer.values?.buildingNumber || "",
    //             "flatNumber": (formReducer.values?.flatNumber !== null) ? (formReducer.values?.flatNumber)?.toString() : '' || "",
    //             "flatFloor": formReducer.values?.flatFloor?.label || "", // edycja
    //         },
    //         "houseType": formReducer.values?.houseType?.label || "",
    //         "roofType": formReducer.values?.roofType?.label || "",
    //         "wallsConstructionType": formReducer.values?.wallsConstructionTypeLabel || "",
    //         "buildYear": formReducer.values?.buildYear?.label || "",
    //         // "propertyArea": formReducer.values?.propertyArea + (formReducer.values.propertyArea !== null) ? 'm2': '' || "",
    //         "propertyArea": formReducer.values?.propertyArea || "",
    //         "beginningDateOfBuilding": formReducer.values?.beginningDateOfBuilding?.label || "",
    //         "expectedBuildingFinishDate": formReducer.values?.expectedBuildingFinishDateLabel || "",
    //         "progressOfWork": formReducer.values?.progressOfWork?.label || "",
    //         "lastRenovationDate": formReducer.values?.lastRenovationDate?.label || "",
    //         "houseOwnershipType": formReducer.values?.houseOwnershipType?.label || "",
    //         "flatOwnershipType": formReducer.values?.flatOwnershipType?.label || "",
    //         "farmSize": formReducer.values?.farmSize + (formReducer.values.farmSize !== null) ? 'ha' : '' || "",
    //         "propertyPermanentlyInhabited": formReducer.values?.propertyPermanentlyInhabited?.label || "",
    //         "economicActivity": formReducer.values?.economicActivity?.label || "",
    //         "areaPartForEconomicActivity": formReducer.values?.areaPartForEconomicActivity?.label || "",
    //         "economicActivityType": formReducer.values?.economicActivityType?.label || "",
    //         "propertyForRent": (formReducer.values?.propertyForRent === 1),
    //         "assignmentToBank": (formReducer.values?.assignmentToBank === 1),
    //         "yearsPropertyWasInsured": formReducer.values?.yearsPropertyWasInsured?.label || "",
    //         "lastInsuranceAgency": formReducer.values?.lastInsuranceAgency?.label || "",
    //         "damageAmountDuringInsurancePeriod": formReducer.values?.damageAmountDuringInsurancePeriod || "",
    //         "floodDamageAmount": formReducer.values?.floodDamageAmount?.label || "",
    //         "insuranceBeginningDate": formReducer.values?.insuranceBeginningDate || "",
    //         "wallsAndPermanentElementsInsuranceCovered": formReducer.values?.wallsAndPermanentElementsInsuranceCovered || false,
    //         "equipmentInsuranceCovered": formReducer.values?.equipmentInsuranceCovered || false,
    //         "farmInsuranceCoverageObligatory": formReducer.values?.farmInsuranceCoverageObligatory || false,
    //         "farmInsuranceCoverageVoluntary": formReducer.values?.farmInsuranceCoverageVoluntary || false,
    //         "isRandomEventInsured": formReducer.values?.isRandomEventInsured || false,
    //         "isTheftInsured": formReducer.values?.isTheftInsured || false,
    //         "isFloodInsured": formReducer.values?.isFloodInsured || false,
    //         "isVandalismInsured": formReducer.values?.isVandalismInsured || false,
    //         "isGlassItemInsured": formReducer.values?.isGlassItemInsured || false,
    //         "isIntercom": formReducer.values?.isIntercom || false,
    //         "isLocalAlarm": formReducer.values?.isLocalAlarm || false,
    //         "isAlarmWithIntervention": formReducer.values?.isAlarmWithIntervention || false,
    //         "isConstantSupervision": formReducer.values?.isConstantSupervision || false,
    //         "isSecurityDoor": formReducer.values?.isSecurityDoor || false,
    //         "isMonitoring": formReducer.values?.isMonitoring || false,
    //         "isWindowSecurity": formReducer.values?.isWindowSecurity || false,
    //         "multiValveLockAmount": formReducer.values?.multiValveLockAmount || "",
    //         "certifiedLock": formReducer.values?.certifiedLock || "",
    //         // "propertyValue": (formReducer.values?.propertyValue !== null) ? (formReducer.values?.propertyValue).toString() : '' || "",
    //         "permanentItemsInsuranceAmount": (formReducer.values?.permanentItemsInsuranceAmount)?.toString() || "",
    //         "equipmentInsuranceAmount": (formReducer.values?.equipmentInsuranceAmount)?.toString() || "",
    //         "isOCInsuranceInPrivateLife": (formReducer.values?.isOCInsuranceInPrivateLife === 1),
    //     }
    // }
    // console.log('request data ' + JSON.stringify(requestData))

    // dispatch(sendDataToFortWebApiAction(JSON.stringify(formReducer.values)))
    console.log(JSON.stringify(values))
}

CleanFifthStep = reduxForm({
    form: 'propertyForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    // forceUnregisterOnUnmount: true,
    validate,
    onSubmit
})(CleanFifthStep);

const selector = formValueSelector('propertyForm')
CleanFifthStep = connect(state => {
    // can select values individually
    const propertyForRent = selector(state, 'home[propertyForRent]')
    const propertyArea = selector(state, 'home[propertyArea]')
    const propertyType = selector(state, 'home[propertyType]')
    const houseType = selector(state, 'home[houseType]')
    const roofType = selector(state, 'home[roofType]')
    const wallsConstructionType = selector(state, 'home[wallsConstructionType]')
    const buildYearLabel = selector(state, 'home[buildYearLabel]')
    const city = selector(state, 'home[address[[city]]')
    const houseOwnershipType = selector(state, 'home[houseOwnershipType]')
    const flatOwnershipType = selector(state, 'home[flatOwnershipType]')
    const economicActivity = selector(state, 'home[economicActivity]')
    const assignmentToBank = selector(state, 'home[assignmentToBank]')
    const yearsPropertyWasInsured = selector(state, 'home[yearsPropertyWasInsured]')
    const lastInsuranceAgency = selector(state, 'home[lastInsuranceAgency]')
    const floodDamageAmount = selector(state, 'home[floodDamageAmount]')
    const multiValveLockAmount = selector(state, 'home[multiValveLockAmount]')
    const certifiedLock = selector(state, 'home[certifiedLock]')
    const phoneNumber = selector(state, 'person[phoneNumber]')
    const isOCInsuranceInPrivateLife = selector(state, 'home[isOCInsuranceInPrivateLife]')

    // values: getFormValues('propertyForm')(state)
    // const favoriteColorValue = selector(state, 'favoriteColor')
    // or together as a group
    // const { firstName, lastName } = selector(state, 'firstName', 'lastName')
    return {
        propertyForRent,
        propertyArea,
        propertyType,
        houseType,
        roofType,
        wallsConstructionType,
        buildYearLabel,
        city,
        houseOwnershipType,
        flatOwnershipType,
        economicActivity,
        assignmentToBank,
        yearsPropertyWasInsured,
        lastInsuranceAgency,
        floodDamageAmount,
        multiValveLockAmount,
        certifiedLock,
        phoneNumber,
        isOCInsuranceInPrivateLife,
        // favoriteColorValue,
        // fullName: `${firstName || ''} ${lastName || ''}`
    }
})(CleanFifthStep)
export default CleanFifthStep;
