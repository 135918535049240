export const houseOwnershipTypeData = [{
    "value": 1,
    "label": "Własność"
},
    {
        "value": 2,
        "label": "Hipoteka"
    },
    {
        "value": 3,
        "label": "Najem"
    },
    {
        "value": 4,
        "label": "Spółdzielcze"
    },
    {
        "value": 5,
        "label": "Własność przeznaczona na wynajem"
    },
    {
        "value": 6,
        "label": "Cesja"
    },
    {
        "value": 7,
        "label": "Inna"
    }
]

export const propertyInConstructionOwnershipTypeData = [{
		"value": 1,
		"label": "Własność"
	},
    {
        "value": 2,
        "label": "Hipoteka"
    },
    {
        "value": 3,
        "label": "Najem"
    },
    {
        "value": 4,
        "label": "Spółdzielcze"
    },
    {
        "value": 5,
        "label": "Własność przeznaczona na wynajem"
    },
    {
        "value": 6,
        "label": "Cesja"
    },
    {
        "value": 7,
        "label": "Inna"
    }
]

export const flatOwnershipTypeData = [{
    "value": 1,
    "label": "Własność"
}, {
    "value": 2,
    "label": "Hipoteka"
}, {
    "value": 3,
    "label": "Najem"
}, {
    "value": 4,
    "label": "Spółdzielcze"
}, {
    "value": 5,
    "label": "Własność przeznaczona na wynajem"
}, {
    "value": 6,
    "label": "Cesja"
}, {
    "value": 7,
    "label": "Inna"
}]

export const propertyPermanentlyInhabitedData = [
    {
        "value": 1,
        "label": "Tak"
    },
    {
        "value": 2,
        "label": "Nie, do 30 dni"
    },
    {
        "value": 3,
        "label": "Nie"
    },
]

export const economicActivityData = [{
    "value": 1,
    "label": "Tak, jest wykonywana"
}, {
    "value": 2,
    "label": "Nie, nie jest wykonywana"
}]

export const areaPartForEconomicActivityData = [{
    "value": 1,
    "label": "Mniej niż połowa"
}, {
    "value": 2,
    "label": "Połowa lub więcej"
}]

export const economicAcitvityTypeData = [{
    "value": 1,
    "label": "Biurowa"
}, {
    "value": 2,
    "label": "Handel"
}, {
    "value": 3,
    "label": "Usługi"
}, {
    "value": 4,
    "label": "Produkcja"
}
]

export const prefferedContactTimeData = [{
    value: 1,
    label: '07:00',
}, {
    value: 2,
    label: '08:00',
}, {
    value: 3,
    label: '09:00',
}, {
    value: 4,
    label: '10:00',
}, {
    value: 5,
    label: '11:00',
}, {
    value: 6,
    label: '12:00',
}, {
    value: 7,
    label: '13:00',
}, {
    value: 8,
    label: '14:00',
}, {
    value: 9,
    label: '15:00',
}, {
    value: 10,
    label: '16:00',
}, {
    value: 11,
    label: '17:00',
}, {
    value: 12,
    label: '18:00',
}, {
    value: 13,
    label: '19:00',
}, {
    value: 14,
    label: '20:00',
}, {
    value: 15,
    label: '21:00',
}, {
    value: 16,
    label: '22:00',
}]

export const ownershipTypeTooltip = {
    "header": "Forma własności",
    "description": "Podaj, jakie prawo do nieruchomości posiada Ubezpieczający."
}
