import React from "react"
import {useSelector} from "react-redux";
import CleanFifthStep from "../components/fifthStep/CleanFifthStep";

const Step5 = () => {
	const isDataLoadFromLocalStorage = useSelector(state => state.loadDataFromLocalStorage.loadDataFromLocalstorage);
	return (
		<>
			<CleanFifthStep/>
		</>
	)
};

export default Step5
